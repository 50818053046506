.table {
  display: flex;
  flex-flow: column wrap;
  color: white;
  border: 1px solid #d7d7d7;
  &--header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1;
    font-weight: 600;
    & .table--row {
      font-weight: 500;
      font-size: .9em;
    }
  }
  &--body {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    overflow: auto;
    & .table--row {
      font-weight: 400;
      font-size: .8em;
    }
  }
  &--row {
    display: inline-flex;
    flex-direction: row;
    gap: .5em;
    flex: 1;
  }
  &--td {
    padding: .5em .8em;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    text-align: left;
    height: 30px;
  }
}
