.register {
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin: 0 auto;
    padding: 1em 2em;
    &-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &-checkboxes {
      max-width: 80%;
      margin-bottom: 20px;
    }
    & > * {
      margin-top: 1rem;
    }
    & label:first-child {
      margin-top: 0;
    }
    &-wrapper {
      margin-top: 1em;
      @media(max-width: 840px) {
        max-width: 70%;
      }
    }
    &-title {
      font-size: 1.5rem;
      color: #fff;
      @media(min-width: 834px) {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    & > label {
      color: #fff;
      font-size: 1em;
      font-family: Lato;
      font-weight: 700;
      @media(min-width: 834px) {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    & .form-label {
      font-size: 1.1em;
      display: block;
      font-family: Lato;
      margin-right: 1.2vw;
      margin-left: 1.2vw;
      margin-bottom: 10px;
      @media(max-width: 840px) {
        font-size: 1.2em;
      }
      @media screen and (max-width: 400px) {
        font-size: 1.7em;
      }
      & .checkbox_text {
        font-size: 1.1rem;
      }
      & > span {
        color: #fff;
        & a {
          color: #fbcf00;
        }
      }
    }
    & .form-input {
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid #fbcf00;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 15px;
      font-size: 0.9em;
      color: #fff;
      font-family: Lato;
      &::placeholder {
        color: #fff !important;
        font-family: Lato;
      }
      @media screen and (max-width: 400px) {
        font-size: 1.4em;
      }
    }
    & .btn {
      width: 40%;
      align-self: center;
      background: #fbcf00;
      border-radius: 1.8rem;
      font-size: 1.6em;
      font-family: Luckiest Guy;
      text-shadow: 0px 3px 5px #5d5b42;
      @media(max-width: 840px) {
        font-size: 1.8em;
      }
    }
  }
  &-button {
    background-color: #009cff;
    color: white;
    padding: 0.5rem 1rem;
    border: 1px solid #0587ce;
    border-radius: 0.3rem;
    &:disabled {
      opacity: 0.5;
    }
  }
}
