.form {
  &-label {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.87);
    &-text {
      margin-bottom: 0.4em;
      font-weight: 600;
      color: #fff;
    }
  }
  &-checkbox {
    cursor: pointer;
    appearance: none;
    border: 1.5px solid #fbcf00;
    border-radius: 5px;
    width: inherit;
    height: inherit;
    margin: 0;
    background: transparent;
    &:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      background-size: 1.4em 1.4em;
      background-position: center;
    }
    &-wrapper {
      position: relative;
      margin-bottom: 0 !important;
      height: 1.5em;
      width: 1.5em;
      align-self: flex-start;
      &::before {
          content: "\2713";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: rgba(110, 119, 142, 1);
          display: none;
          text-align: center;
          font-size: 1rem;
      }
    }
    &:checked ~ &-wrapper::before {
      display: block;
    }
    &-container {
      & .form-label {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      & span {
        margin-left: 0.8rem;
        font-weight: normal;
        color: rgba(62, 67, 80, 1);
      }
    }
  }
  &-input {
    height: 3.6em;
    padding: 1rem 0.9rem;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    width: 100%;
    outline: none;
  }
  &-error {
    color: #a30101;
    font-size: 0.8rem;
  }
}
