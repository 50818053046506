.btn {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 1.5rem;
  padding: 1rem 0;
  border-style: none;
  &:disabled {
    opacity: .38;
    cursor: not-allowed;
  }
  &-full {
    width: 100%;
  }
  &-search {
    padding: 0.75rem 1.25rem;
    font-weight: normal;
    @media(max-width: 834px) {
      display: none;
    }
  }
  &-large {
    background: linear-gradient(180deg, #474DCE 0%, #5E63DB 100%);
  }
}
