.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  bottom: 0;
  left: 0;
  padding: 0.5em 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #181717;
  border-top: 2px solid hsla(0,0%,100%,.1);
  color: #aea8a8;
  transition: all .2s ease-in-out;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  @media(max-width: 1024px) {
    font-size: 2vw;
    max-height: 75vh;
  }
  @media(max-width: 540px) {
    font-size: 4.2vw;
    max-height: 50vh;
  }
  & ul {
    & li {
      list-style: none;
      font-size: 0.88em;
      margin-bottom: 1em;
      @media(min-width: 540px) and (max-width: 1024px) {
        font-size: 1em;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: unset;
    cursor: pointer;
    transition: color .3s ease;
    &:hover {
      color: #8dd363;
    }
  }

  &__base {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    transition: height 1s ease;
    @media(max-width: 1024px) {
      overflow: scroll;
      max-height: 100vh;
    }
  }

  &__container {
    margin: 0 1em;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    @media(max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    max-width: 17%;
    @media(max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__title {
    font-size: 0.88em;
    margin-right: 0.5em;
  }

  &__subtitle {
    margin-bottom: 1.3em;
    font-size: 1.4em;
    color: #fff;
    text-transform: uppercase;
    @media(max-width: 1024px) {
      margin-bottom: 0.5em;
    }
    @media(max-width: 540px) {
      font-size: 1.2em;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8dd363;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    opacity: .5;
    z-index: 1010;
    cursor: pointer;
    transform: rotate(180deg);
    transition: transform 0.7s ease;
    @media(max-width: 1024px) {
      height: 2em;
      width: 2em;
    }
    &--down {
      transform: rotate(0deg);
    }
  }

  &__licenses {
    max-width: 42%;
    font-size: 1vw;
    @media(max-width: 1024px) {
      max-width: 50%;
    }
    @media(max-width: 540px) {
      max-width: 100%;
      width: 100%;
      font-size: 0.5em;
      margin-top: 4em;
    }
    & ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      @media(max-width: 540px) {
        justify-content: space-between;
      }
      & li {
        max-width: 20em;
        margin: 0 1em 2em 1em;
        transition: filter .3s ease;
        &:hover {
          filter: sepia(1);
        }
      }
    }
  }

  &__connected {
    max-width: 25%;
    @media(max-width: 1024px) {
      max-width: 100%;
      width: 100%;
    }
    @media(min-width: 540px) and (max-width: 1024px) {
      & ul {
        display: flex;
        flex-wrap: wrap;
        & li {
          margin-right: 0.5em;
          width: 22%;
          &:last-of-type {
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }
    @media(max-width: 540px) {
      margin-top: 4em;
      & ul {
        & li {
          font-size: 1em;
        }
      }
    }
    & img {
      max-width: 1.2em;
      margin-right: 0.2em;
      @media(min-width: 540px) and (max-width: 1024px) {
        max-width: 2em;
      }
    }
  }

  &__terms {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    max-width: 100%;
    width: 100%;
    & a {
      font-size: 0.8em;
      color: #aea8a8;
      &:hover {
        color: #fff;
      }
    }
  }

  &--hide {
    display: none;
  }
}

