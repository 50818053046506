.user {
  &__info {
    text-align: center;
  }
  &__email {
    display: flex;
    align-items: center;
    background: #222431;
    border-radius: 24px;
    padding: 8px;
  }
  &__logOut {
    margin-left: 4px;
    display: flex;
  }
  &__balance {
    margin-top: 6px;
  }
}
