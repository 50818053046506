.tabs {
  display: flex;
  flex-direction: column;
  &-header {
    display: flex;
    justify-content: center;
    &--item {
      padding: 0 1.2em;
      text-align: center;
      &-active {
        border-bottom: 2px solid #009cff;
        border-radius: 0;
      }
    }
  }
  &-body {
    &--hidden {
      display: none;
    }
  }
}
