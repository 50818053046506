.description {
  margin: 1.5em auto 0 auto;
  color: white;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.3;
  padding: 0.5em;
  &--cursive {
    font-family: Alex Brush;
    font-size: 2.5em;
  }
  &--white {
    color: white;
    font-family: Lato;
    .gold-text {
      color: #fbcf00;
    }
  }
  .time {
    margin-top: 10px;
  }
  &--gold {
    font-size: 1.5em;
    line-height: 1.5;
    color: #fbcf00;
    font-family: Luckiest Guy;
    font-weight: bold;
    text-shadow: 0px 3px 5px #5d5b42;
  }
  .get-ready {
    margin-top: 3vw;
  }
  &-top-logo {
    margin-bottom: -55vw;
    margin-top: -10vw;
  }
  @media only screen and (min-width: 834px) {
    width: 75%;
    padding: 0;
  }
  @media screen and (max-width: 834px) {
    &-top-logo {
      margin-bottom: -71vw;
    }
    &--title {
      width: 80%;
    }
  }
  @media screen and (max-width: 1050px) {
    &--gold {
      font-size: 1.3em;
    }
  }
}
