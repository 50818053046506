.admin {
  &-container {
    //background: linear-gradient(180deg, #2f3954 0%, #1d202f 100%);
    min-height: 100vh;
  }
  &-wrapper {
    margin: 0 4em;
  }
}

.winners-container,
.players-container {
  & button {
    color: #009cff;
    border: 1px solid #009cff;
    border-radius: 8px;
    padding: .6em 1em;
    margin-bottom: .8em;
    transition: background-color 250ms ease-in-out;
    font-size: 1em;
    &:hover {
      background-color: rgba(0, 156, 255, .2);
    }
  }
}
