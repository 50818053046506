@import "register";
@import "forms";
@import "buttons";
@import "gameLobby";
@import "userInfo";
@import "adminUsers";
@import "tabs";
@import "table";
@import "footer";
@import "description";

@font-face {
    font-family: Walibi;
    src: url(../assets/fonts/walibi-holland.ttf);
}

@font-face {
    font-family: Futura;
    src: url(../assets/fonts/futur.ttf);
}

@font-face {
    font-family: Myriad Pro;
    src: url(../assets/fonts/Myriad_Pro_Regular.ttf);
}

@font-face {
    font-family: Alex Brush;
    src: url(../assets/fonts/AlexBrush-Regular.ttf);
}

@font-face {
    font-family: Lato;
    src: url(../assets/fonts/lato-regular.ttf);
}

@font-face {
    font-family: Luckiest Guy;
    src: url(../assets/fonts/LuckiestGuy.ttf);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body, #root {
    width: 100%;
}

body {
    margin: 0;
    font-size: 16px;
    outline: none;
    font-family: 'Lato', sans-serif;
    overflow: auto;
    min-height: 100vh;
}

#root {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @media(max-width: 1024px) {
        font-size: 1.5vw;
    }
    // & > div {
    //     flex-grow: 1;
    // }
}

#background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-linear-gradient(left, rgb(7, 7, 8) 0%, rgb(44, 45, 43) 80%);
    background: -o-linear-gradient(left, rgb(7, 7, 8) 0%, rgb(44, 45, 43) 80%);
    background: -ms-linear-gradient(left, rgb(7, 7, 8) 0%, rgb(44, 45, 43) 80%);
    background: -moz-linear-gradient(left, rgb(7, 7, 8) 0%, rgb(44, 45, 43) 80%);
    background: linear-gradient(to right, rgb(7, 7, 8) 0%, rgb(44, 45, 43) 80%);
    background-image: url("../assets/fishermanBackground.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    flex-grow: unset;
    z-index: -1;
}

::placeholder {
    font-family: 'Lato', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

label,
button {
    cursor: pointer;
}

button {
    background: unset;
    border: unset;
}

.main {
    &-container {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        //background: linear-gradient(180deg, #2f3954 0%, #1d202f 100%);
        overflow: hidden;
        flex-direction: column;
        &::after {
            content: '';
            display: none;
            position: absolute;
            width: 37.5rem;
            height: 37.5rem;
            background: linear-gradient(45deg, #4C418E 18.91%, rgba(32, 30, 89, 0) 69.99%);
            border-radius: 50%;
            z-index: 0;
            top: 0;
            left: 5%;
            filter: blur(50px);
            transform: translate(0, -5%) matrix(1, 0, 0, -1, 0, 0);
        }
        &-logo {
            position: absolute;
            top: 5%;
            left: 50%;
            transform: translate(-50%, -10%);
            z-index: 10;
        }
    }
}
