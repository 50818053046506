.game-lobby {
  height: auto;
  min-height: 100%;
  &--search {
    margin: 1.5em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    & .form-label {
      width: 100%;
      @media(min-width: 834px) {
        width: 60%;
        margin-right: 2em;
      }
    }
    & .form-input {
      color: white;
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 1.5rem;
      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  &--body {
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media(min-width: 834px) {
      padding: 0 2em;
    }
    @media(min-width: 1194px) {
      padding: 0 6em;
    }
    &-item {
      width: 45%;
      margin: 0.5em;
      overflow: hidden;
      color: white;
      font-size: 0.875rem;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 6px;
      display: flex;
      transition: all .3s ease;
      &:hover {
        box-shadow: 0 0 8px 3px rgba(252, 253, 118, 0.9);
      }
      &:active {
        transform: scale(0.85);
        outline: none;
        box-shadow: 0 0 8px 3px rgba(252, 253, 118, 0.9);
        transition: transform 0s ease;
      }
      @media(min-width:1194px ) {
        width: 35%;
      }
      & div {
        margin: 0.5rem 0;
      }
      & img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
  &-logo {
    width: 100%;
  }
}
.desktop-video {
  display: none;
}
@media(min-width: 834px) {
  .mobile-video {
    display: none;
  }
  .desktop-video {
    display: block;
  }
}
.navbar {
  //padding: 0.6em 0.5em;
  //background: black;
  //box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  font-weight: 700;
  justify-content: space-between;
  & video {
    width: 100%;
    pointer-events: none;
  }
  &-logo {
    display: none;
    @media(min-width: 834px) {
      display: block;
    }
    &--sm {
      @media(min-width: 834px) {
        display: none;
      }
    }
  }
  &-info {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.88rem;
    margin-left: auto;
    &-separator {
      margin: 0 1em;
      width: 1px;
      background: rgba(255, 255, 255, 0.25);
      height: 2.5rem;
    }
    &-user {
      margin-right: 1em;
      background: rgba(34, 36, 49, 1);
      padding: 0.75rem 1.25rem;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      & span {
        margin-right: 0.5rem;
      }
    }
  }
}

